import { MaskInput } from "maska";

document.addEventListener("DOMContentLoaded", createMaskInput);
document.addEventListener("turbo:render", createMaskInput);

function createMaskInput(event) {
  event.preventDefault();

  new MaskInput("[data-maska]", {
    tokens: {
      A: { pattern: /[A-Z]/, transform: (chr) => chr.toUpperCase() },
    },
  });
}
