import * as bootstrap from "bootstrap";

document.addEventListener("DOMContentLoaded", popover);
document.addEventListener("turbo:render", popover);

function popover(event) {
  event.preventDefault();

  const popoverTriggerList = document.querySelectorAll(
    '[data-bs-toggle="popover"]'
  );

  popoverTriggerList.forEach((popoverTriggerEl) => {
    new bootstrap.Popover(popoverTriggerEl);
  });
}
